export namespace NotificationCentre {
  export interface IUserMessage {
    id: number,
    subject: string,
    marking_window_id: number,
    message: string,
    sender_uid: number,
    is_auto: number,
    timestamp: string,
    img_url: string,
    channel_id: number,
    // Details <IUserMessageRecipient>
    recipient_count?: number,
    recipient_read_count?: number,
    recipient_group_id?: number,
    recipient_role_id?: number,
    read?: ReadStatus,
    read_on?: string,
    archived?: ArchiveStatus
  }

  export interface IUserMessageRecipient {
    id: number,
    message_id: number,
    recipient_uid: number,
    recipient_group_id: number,
    recipient_role_id: number,
    read: ReadStatus,
    read_on: string,
    archived: ArchiveStatus
  }

  export interface IFilterGroupConfig {[key: string]: IFilterGroup} // Top level filters are OR'd together
  
  export interface IFilterGroup {
    name: string,
    description?: string,
    subfilters?: IFilterGroupConfig,  // Nested subfilters - applicable by AND logic.
    input?: IFilterInput,
    selected?: boolean,
  }

  export interface IFilterInput {
    type: FilterInputType,
    value: string,
  }

  export enum FilterInputType {
    TEXT = 'text'
  }

  export enum ReadStatus {
    READ = 1,
    UNREAD = 0
  }

  export enum ArchiveStatus {
    ARCHIVED = 1,
    UNARCHIVED = 0
  }
}
