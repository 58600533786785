import { Injectable, OnDestroy } from '@angular/core';
import { LangService } from '../core/lang.service';
import { Router } from '@angular/router';
import { StudentDashboardView } from './view-student-g9-dashboard/view-student-g9-dashboard.component';
import { AuthService } from '../api/auth.service';
import { RoutesService } from '../api/routes.service';
import { StudentG9ConnectionService } from './student-g9-connection.service';
import { OnlineOrPaperService } from '../ui-testrunner/online-or-paper.service';
import { G9DemoDataService } from '../ui-schooladmin/g9-demo-data.service';

@Injectable({
  providedIn: 'root'
})
export class StudentG9DashboardService implements OnDestroy {

  constructor(
    private lang: LangService,
    private router: Router,
    private auth: AuthService,
    private routes: RoutesService,
    private studentG9Connection: StudentG9ConnectionService,
    private onlineOrPaper: OnlineOrPaperService,
    private g9DemoData: G9DemoDataService
  ) {
  }

  userSub;
  private schoolSoftLockEnable: boolean = false
  private schoolSoftLockPjEnable: boolean = false
  private schoolSoftLockG9Enable: boolean = false
  private schoolSoftLockG10Enable: boolean = false

  init() {
    if(!this.userSub) {
      this.userSub = this.auth.user().subscribe(async (info) => {
        await this.studentG9Connection.onUserInfoChange(info);
      });
    }
  }


  ngOnDestroy() {
    if(this.userSub) {
      this.userSub.unsubscribe();
    }
  }

  logAction(logActionSlug: string, body: {session_id?: string, state?: object, info?: object}, slugPrefix? : string): void {
    if(!this.auth.userIsStudent()) {
      return;
    }
    let slug = logActionSlug;
    if (slugPrefix !== null && slugPrefix !== undefined && slugPrefix.trim() !== '') {
      slug = `${slugPrefix}_${logActionSlug}`;
    }
    this.auth.apiCreate( this.routes.LOG,
      {
        slug: slug,
        data: {
          uid: this.auth.getUid(),
          ...body
        }
      }
    )
  }

  goToDashboardView = (view: StudentDashboardView, isOsslt?: boolean) => {
    if(isOsslt){
      const baseURL = `${this.lang.c()}/student/dashboard`;
      this.router.navigate([`${baseURL}/${view}`]);
    } else {
      const baseURL = `${this.lang.c()}/student/dashboard`;
      this.router.navigate([`${baseURL}/${view}`]);
    }
  }

  configureQueryParams(allow_inactive_subsession?: boolean) {
    if (this.auth.u()) {
      return {
        query: {
          schl_class_group_id: this.auth.u().sch_class_group_id,
          allow_inactive_subsession: allow_inactive_subsession
        }
      }
    }
    return null;
  }

  async loadAssessments(allow_inactive_subsession?: boolean){
    return this.auth
      .apiFind(this.routes.STUDENT_SESSION, this.configureQueryParams(allow_inactive_subsession))
  }

  async loadAttempt(test_session_id, route:string = this.routes.STUDENT_SESSION){
    const sch_class_group_id = this.auth.u()?.sch_class_group_id;
    if(!sch_class_group_id) {
      return Promise.resolve();
    }

    return this.auth
      .apiGet(route, test_session_id, {query:{KIOSK_PASSWORD: this.auth.activeKioskPassword, schl_class_group_id: sch_class_group_id}}).then((res) => {
        this.schoolSoftLockEnable = !!res[0].is_softlock_enabled
        this.schoolSoftLockPjEnable = !!res[0].is_softlock_enabled_pj
        this.schoolSoftLockG9Enable = !!res[0].is_softlock_enabled_g9
        this.schoolSoftLockG10Enable = !!res[0].is_softlock_enabled_g10
        this.onlineOrPaper.setIsPaper(res[0].IsCrScanDefault, this.g9DemoData.asmtSlugToCurricShort(res[0].asmt_slug))
        return res;
      })
  }

  getSchoolSoftLockEnablePj() {
    return this.schoolSoftLockPjEnable;
  }

  getSchoolSoftLockEnableG9() {
    return this.schoolSoftLockG9Enable;
  }

  getSchoolSoftLockEnableG10() {
    return this.schoolSoftLockG10Enable;
  }

  getSchoolSoftLockEnable(){
    return this.schoolSoftLockEnable
  }
}
